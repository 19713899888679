import React from 'react';
import './App.css';
import Map from './pages/map';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Schedule from './pages/schedule';
import Other from './pages/other';
import Check from './pages/check';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/map" element={<Map />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/other" element={<Other />} />
        <Route path="/check" element={<Check />} />
      </Routes>
    </div>
  );
}

export default App;
