import SD from './sd';
import './check.css';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

const itemList = [
    "ニンテンドーSwitch",
    "寝巻き",
    "次の日の服",
    "運動着",
    "運動靴",
    "おやつ",
    "おかね",
    "身分証明書",
    "ヘパリーゼ",
    "レッドブル",
    "髭剃り",
    "洗顔",
    "コンタクト",
    "寝る用メガネ",
    "アルコールジェル",
    "マスク(伊藤オブザイヤー)",
    "シャンプーリンス(必要な人)",
    "メイク",
    "メイク落とし",
    "携帯",
    "財布",
    "家の鍵",
    "汗拭きタオル",
    "サイン色紙",
    "ポケカ",
    "チェキ",
];

const Check = () => {

    const [item, setItem] = useState<{ key: string; value: string }[]>([]);

    // 初回設定
    useEffect(() => {
        const initItem = itemList.map((v) => {
            const value = localStorage.getItem(v);
            return { key: v, value: value ? value : "0" };
        });
        setItem(initItem);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        localStorage.setItem(event.target.name, event.target.checked ? "1" : "0")
        const fuga = item.filter((v) => {
            return v.key !== event.target.name
        });

        const piyo = [...fuga, { key: event.target.name, value: event.target.checked ? "1" : "0" }]

        console.log(piyo)

        const sorted = itemList.map((v) => {
            const a = piyo.find((v2) =>
                v2.key === v
            )
            return a ? a : { key: "", value: "" }
        })

        setItem(sorted);
    };

    return (
        <>
            <div>
                <div>
                    <FormGroup>
                        {item && item.map((v, i) => {
                            return <FormControlLabel key={v.key + i}
                                control={
                                    <Checkbox key={v.key + i + 1} checked={v.value === "1"} onChange={handleChange} name={v.key} />
                                }
                                label={v.key}
                            />
                        })}
                    </FormGroup>
                </div>
            </div>
            <SD />
        </>
    );
}

export default Check;