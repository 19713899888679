import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MapIcon from '@mui/icons-material/Map';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useNavigate } from 'react-router-dom';


const SD = () => {

    const navigate = useNavigate();
    const actions = [
        { icon: <FactCheckIcon />, name: 'CheckList', click: () => navigate('/check') },
        { icon: <DirectionsWalkIcon />, name: 'Other', click: () => navigate('/other') },
        { icon: <ScheduleIcon />, name: 'Schedule', click: () => navigate('/schedule') },
        { icon: <MapIcon />, name: 'Map', click: () => navigate('/map') },
    ];

    return (
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.click}
                />
            ))}
        </SpeedDial>)
};

export default SD;