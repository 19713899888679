import {
    Animator, ScrollContainer, ScrollPage, batch, FadeIn, FadeOut, Move, Sticky,
} from "react-scroll-motion";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from "@mui/material";
import SD from "./sd";

const data = [
    {
        time: '1日目 9:15',
        title: '北都交通バスカウンター(ANA到着口)',
        description: '豊平郵便局へ',
        web_url: 'https://www.chuo-bus.co.jp/airport/index.cgi?ope=sap_pole&trjc=2705&trjs=29#AnaPole',
        image_url: './bus.png'
    },
    {
        time: '1日目 10:00',
        title: '美園 集合',
        description: 'ダークモカチップフラペチーノ ベンティ',
        web_url: 'https://product.starbucks.co.jp/beverage/frappuccino/4524785166059/',
        image_url: './venti.jpeg'
    },
    {
        time: '1日目 11:30',
        title: '道の駅 望羊中山',
        description: 'あげいも 350円 636kcal',
        web_url: 'https://ageimo.com/',
        image_url: './ageimo.jpeg'
    },
    {
        time: '1日目 12:00',
        title: '郷の駅 ホッときもべつ',
        description: '羊蹄山・尻別岳・喜茂別川を一望できるビューポイントもあります',
        web_url: 'https://kimobetsu-kankou.com/information/76/',
        image_url: './kimobetsu.jpeg'
    },
    {
        time: '1日目 12:30',
        title: 'ふきだし公園',
        description: '「名水の里」として多くの人に親しまれています',
        web_url: 'https://kyogoku-kanko.jp/park.html',
        image_url: './fukidashi.jpeg'
    },
    {
        time: '1日目 13:00-16:00',
        title: 'ヴィラ ルピシア',
        description: 'オリジナルティー',
        web_url: 'https://villa.lupicia.co.jp/ja/',
        image_url: './lupicia.jpeg'
    },
    {
        time: '1日目 13:00-16:00',
        title: '高橋牧場',
        description: '夕飯のピザを予約',
        web_url: 'https://www.niseko-takahashi.jp/',
        image_url: './takahashi.jpeg'
    },
    {
        time: '1日目 13:00-16:00',
        title: '天然酵母ぐずぐず',
        description: '翌朝のパンを調達',
        web_url: 'http://www.guzuguzu.com/',
        image_url: './guzuguzu.jpeg'
    },
    {
        time: '1日目 16:30',
        title: 'OAC Niseko Balloon (ニセコバルーン)',
        description: 'たかいたかーい',
        web_url: 'https://www.oac-japan.com/service/balloon.html',
        image_url: './niseko_balloon.jpeg'
    },
    {
        time: '1日目 17:00',
        title: 'ここで二手にわかれよう',
        description: '高橋牧場に戻ってピザ受け取り or 宿に先回りして夕飯準備',
        web_url: 'https://dictionary.goo.ne.jp/thsrs/13923/meaning/m0u/',
        image_url: './futate.png'
    },
    {
        time: '1日目 18:00',
        title: 'AYA Niseko',
        description: 'やどやにとまると　きずが　かいふくするだろう。',
        web_url: 'https://www.ayaniseko.com/ja/',
        image_url: './aya_niseko.jpeg'
    },
    {
        time: '2日目 11:30',
        title: 'NACニセコアドベンチャーセンター',
        description: 'アラサーの肉体 VS 数多のアクティビティ',
        web_url: 'https://nacadventures.jp/',
        image_url: './nac.jpeg'
    },
];

const FadeUp = batch(FadeIn(0, 1), FadeOut(1, 0), Move(0, 300), Sticky());

const cardList = data.map(v => {
    return (
        <ScrollPage key={v.title}>
            <Animator animation={FadeUp}>
                <Card sx={{ width: '90vw' }}>
                    <CardMedia
                        sx={{ height: '60vw' }}
                        component="img"
                        image={v.image_url}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {v.time}
                        </Typography>
                        <Link href={v.web_url} variant="h5">{v.title}</Link>
                        <Typography variant="body2" color="text.secondary">
                            {v.description}
                        </Typography>
                    </CardContent>
                </Card>
            </Animator>
        </ScrollPage>
    )
});


const Schedule = () => {
    return (
        <>
            <ScrollContainer>{cardList}</ScrollContainer>
            <SD />
        </>
    );
}

export default Schedule;