import Button from '@mui/material/Button';
import MapIcon from '@mui/icons-material/Map';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useNavigate } from 'react-router-dom';

const useStyles = {
    buttonSteps: [
        {
            width: '90vw',
            height: '15vh',
            margin: "2vh 5vw 2vh 5vw",
            fontSize: "7vw",
            fontWeight: 'bold',
            backgroundColor: '#c7d9b8',
            color: '#302b33'
        }
    ]
};

const Home = () => {
    const navigate = useNavigate();
    return (
        <>
            <Button style={useStyles.buttonSteps[0]} startIcon={<MapIcon />} variant="contained" size="large" onClick={() => navigate('/map')}>
                地図
            </Button>
            <Button style={useStyles.buttonSteps[0]} startIcon={<ScheduleIcon />} variant="contained" size="large" onClick={() => navigate('/schedule')}>
                スケジュール
            </Button>
            <Button style={useStyles.buttonSteps[0]} startIcon={<DirectionsWalkIcon />} variant="contained" size="large" onClick={() => navigate('/other')}>
                候補
            </Button>
            <Button style={useStyles.buttonSteps[0]} startIcon={<FactCheckIcon />} variant="contained" size="large" onClick={() => navigate('/check')}>
                持ち物
            </Button>
            {/* <Button style={useStyles.buttonSteps[0]} variant="contained" size="large" onClick={() => navigate('/otanoshimi')}>
                ?
            </Button> */}
            <>v1.0.0</>
        </>
    );
}

export default Home;