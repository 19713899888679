import './map.css';
import SD from './sd';

const Map = () => {
    return (
        <>
            <iframe src="https://www.google.com/maps/d/embed?mid=1zYquDxlSso9EzCnBPGXTEa3vYvm9HuY&ehbc=2E312F" title='niseko_map'></iframe>
            <SD />
        </>
    );
}

export default Map;